/* Contact.css */
.contact-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .page-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .contact-info {
    margin-bottom: 40px;
  }
  
  .contact-info p {
    margin-bottom: 10px;
  }
  
  .contact-info ul {
    list-style: none;
    padding: 0;
  }
  
  .contact-info li {
    margin-bottom: 5px;
  }
  
  .contact-form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s ease;
  }
  
  textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  input[type="text"]:focus,
  input[type="email"]:focus,
  textarea:focus {
    border-color: #ff8800;
  }
  
  button[type="submit"] {
    background-color: #ff8800;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button[type="submit"]:hover {
    background-color: #d66a00;
  }
  