/* News.css */
.news-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .page-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .news-item {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .news-image {
    width: 120px;
    height: 120px;
    border-radius: 5px;
    margin-right: 20px;
  }
  
  .news-details {
    flex: 1;
  }
  
  .news-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .news-description {
    font-size: 16px;
    line-height: 1.6;
  }
  