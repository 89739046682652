/* Artists.css */
.artists-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .page-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .artist {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .artist-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .artist-details {
    flex: 1;
  }
  
  .artist-name {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .artist-bio {
    font-size: 16px;
    line-height: 1.6;
  }
  