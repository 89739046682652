/* Testimonials.css */
.testimonials-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .page-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .testimonial {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .testimonial-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .testimonial-details {
    flex: 1;
  }
  
  .quote {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .source {
    font-size: 16px;
    font-style: italic;
  }
  