
/* Animation for the main title */
.home-title {
    font-size: 48px;
    margin-bottom: 20px;
    animation: slideInLeft 1s ease-out forwards;
  }
  
  /* Animation for the subtitle */
  .home-subtitle {
    font-size: 24px;
    margin-bottom: 40px;
    animation: slideInRight 1s ease-out forwards;
  }
  
  /* Animation for the call-to-action button */
  .home-button {
    animation: pulse 1s infinite alternate;
  }
  
  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideInRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1);
    }
  }
  